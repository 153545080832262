import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 3rem 3rem;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #E9ECEF;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;

  & p {
    padding: 15px 10px;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.7em;
  }

  @media screen and (min-width: 900px) {
    width: 45%;
  }

  &.space {
    justify-content: center;
    align-content: center;
    text-align: center;
  }

  &.w100 {
    width: 100%;
  }
`;

export const Intro = styled.p`
  width: 100%;
  font-weight: 500;
`;

export const Form = styled.form`
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
`;

interface WidthProps {
  width?: string
}
export const Input = styled.input<WidthProps>`
  width: 100%;
  border: 1px solid #333;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 3%;

  @media screen and (min-width: 600px) {
    width: ${({ width }) => (width ?? '100%')};
  }
`;

export const TextArea = styled.textarea<WidthProps>`
  width: 100%;
  border: 1px solid #333;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 3%;
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 0.3em 1em;
  font-size: 1.3rem;
  color: #2D3E77;
  line-height: 1.7em;
  border: 2px solid;
  transition: all .2s;
  margin-left: auto;
  margin-bottom: 10px;
  
  & .plus {
    opacity: 0;
    position: absolute;
  }
  
  &:hover {
    & .plus {
      opacity: 1;
      right: 15px;
    }
    border: 2px solid transparent;
    background-color: rgba(0,0,0,.05);
    padding: 0.3em 2em 0.3em 0.7em;
    cursor: pointer;
  }
`;

export const Image = styled(GatsbyImage)`
  display: inline-block;
  position: relative;
  width: 80%;
  margin: auto;
`;

export const ContactInformation = styled.div`
  width: auto;
  margin: 7px 15px;
  font-weight: 500;

  .icon {
    color: #053977;
    margin-right: 15px;
    margin-top: 7px;
    font-size: 1.5rem;
    @media screen and (min-width: 788px) {
      font-size: 1.2rem;
    }
  }
`;

export const InternalLink = styled.a`
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;
