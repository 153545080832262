import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { ImLocation } from 'react-icons/im';
import { BsFillTelephoneForwardFill } from 'react-icons/bs';
import axios from 'axios';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TopBar from '../components/top-bar/top-bar';
import {
  Container, Section, Wrapper, Image, Intro, Input, Form, TextArea, Button, ContactInformation, InternalLink,
} from '../styles/contactenos.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Contactenos"}) {
        title
        images {
          gatsbyImageData
          id
        }
      }
    }
  `);

  const { images } = data.contentfulPages;

  const onSubmitEvent = async (event: any) => {
    event.preventDefault();
    let isValid = true;
    const dataEmail = {} as any;
    const lengthInputs = (event?.target.length ?? 1) - 1;

    for (let i = 0; i < lengthInputs; i += 1) {
      const input = event.target;
      if (input[i].name !== 'empresa' && input[i].name !== '_gotcha') {
        if (input[i].value === '') {
          isValid = false;
        }
        dataEmail[input[i].name] = input[i].value;
      }
    }
    if (event?.target[lengthInputs - 1].value !== '') {
      isValid = false;
    }

    if (isValid) {
      delete dataEmail[event?.target[lengthInputs - 1].name];
      const dataGtag = {
        event_category: 'Contactenos',
        event_label: 'Formulario',
      };
      if (typeof (window as any).gtag !== 'undefined') (window as any).gtag('event', 'Click', { ...dataGtag });
      try {
        await axios.post('https://2azanhglxu5keygefor7l2rmzm0ajfhc.lambda-url.us-west-1.on.aws', dataEmail);
        event.target.reset();
      } catch (error) {
        // console.log(error);
      }
    }
  };

  return (
    <Layout>
      <main>
        <TopBar title="Contactenos" imageName="productos.jpg" />
        <Section>
          <Wrapper>
            <Container>
              <Intro>Complete el siguiente formulario y nos pondremos en contacto con usted lo antes posible.</Intro>
              <Form method="POST" onSubmit={onSubmitEvent}>
                <Input type="text" name="nombre" placeholder="* Nombre" width="48%" required />
                <Input type="email" name="correo" placeholder="* Correo electronico" width="48%" required />
                <Input type="tel" name="telefono" placeholder="* Telefono" width="48%" required />
                <Input type="text" name="empresa" placeholder="Empresa (opcional)" width="48%" />
                <Input type="text" name="direccion" placeholder="* Direccion" required />
                <TextArea name="mensaje" placeholder="* Mensaje" rows={10} required />
                <Input type="hidden" name="_gotcha" />
                <Button type="submit">Enviar <AiFillCaretRight className="plus" /></Button>
              </Form>
            </Container>

            <Container className="space">
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Canales de comunicaciones" />
              <ContactInformation><ImLocation className="icon" />Carrera 8 este # 25-60 sur Bogota/colombia</ContactInformation>
              <ContactInformation><InternalLink href="tel:2069259"><BsFillTelephoneForwardFill className="icon" />206-9259</InternalLink></ContactInformation>
              <ContactInformation><InternalLink href="tel:3102474432"><BsFillTelephoneForwardFill className="icon" />310-2474432</InternalLink></ContactInformation>
              <ContactInformation><InternalLink href="tel:3138922264"><BsFillTelephoneForwardFill className="icon" />313-8922264</InternalLink></ContactInformation>
              <ContactInformation><InternalLink href="mailto:gerencia@electriredes.com"><GrMail className="icon" />gerencia@electriredes.com</InternalLink></ContactInformation>
              <ContactInformation><InternalLink href="mailto:ventas@electriredes.com"><GrMail className="icon" />ventas@electriredes.com</InternalLink></ContactInformation>
            </Container>

            <Container className="w100">
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11495.422124816329!2d-74.08909219370877!3d4.565787673444965!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96064c4b680419d4!2sElectriredes+Lussan!5e0!3m2!1ses-419!2sco!4v1542230699165" width="100%" height="450" allowFullScreen />
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Contactenos" />;
